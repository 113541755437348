import React, {useState, useEffect} from 'react'

import Column from 'components/Layout/Column'
import Columns from 'components/Layout/Columns'
import HeaderThree from 'components/Headers/HeaderThree'
import Layout from 'UserApp/components/Layout'
import Text from 'components/Text'
import Link from 'sharedComponents/Link'
import AlarmForm from 'UserApp/containers/Alarm/Form'
// import MaxTimeAwayForm from 'UserApp/containers/Alarm/MaxTimeAwayForm'

import style from './style.module.scss'
import SOSButton from 'UserApp/components/Alarm/SOSButton'
import useUser from 'UserApp/hooks/useUser'
import { Button } from 'components/Forms'

function DefaultAlarmScreen(props) {
  const { locationDetails } = props
  const [user, setUser] = useState()
  const userData = useUser();

  useEffect(() => {
    setUser(userData);
  }, []);

  return (
    (user?.user.hasSubscription === true) ?
    (user?.user.hasSubscription &&
    <Layout title='Alarms' customButton={() => <SOSButton />}>
      <Columns>
        <Column>
          <HeaderThree className={style.header}>
            Daily check-in time
          </HeaderThree>
          <Text className={style.text}>
            What time would you like us to check in to confirm you and your pets
            are safe?
          </Text>
          <Link className={style.link} to='/alarm/daily-check-in-alarms'>
            Learn how daily alarms work
          </Link>
          <AlarmForm />

          <HeaderThree className={style.bottomHeader}>
            Location & activity details
          </HeaderThree>

          <div className={style.locationDetails}>
            {locationDetails || (
              <em>Click edit to set location & activity details</em>
            )}
          </div>

          <Link to='/alarm/location-details' className={style.button}>
            Edit
          </Link>
        </Column>
        <Column>
          <HeaderThree className={style.header}>
            Automatic based on location
          </HeaderThree>
          <Text>
            What’s the longest you’d allow your pets to be without care?
          </Text>
          <Link className={style.link2} to='/alarm/location-alarms'>
            Learn how location based alarms work
          </Link>
          {/* <MaxTimeAwayForm /> */}
          <Text className={style.note}>
            Automatic based on location alarms can only be activated from your
            mobile device. The alarm will automatically turn on when you leave
            home and off when you return home to your pets.
          </Text>
        </Column>
      </Columns>
      </Layout>
      ) : ((user?.user.hasSubscription === false || user?.user.hasSubscription === null) ?
      <Layout
      title='Alarms'
      >
          <div>
              <div className={style.modal_content}>
                <p className={style.para}>Feature only offered under Pet Notify premium</p>
                <Button 
                  to='/settings/upgrade'
                  className={style.button}
                >
                  Upgrade to premium
                </Button>
              </div>
          </div>
      </Layout>
      : ""
    )
  )
}

export default DefaultAlarmScreen
