import React,{useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import Layout from 'UserApp/components/Layout'
import Columns from 'components/Layout/Columns'
import Column from 'components/Layout/Column'
import Modal from 'components/Modal'
import Text from 'components/Text'
import { ContactType } from 'types'

import EmergencyContactsList from 'UserApp/components/Contacts/List/Emergency'
import OtherContactsList from 'UserApp/components/Contacts/List/Other'
import ContactForm from 'UserApp/containers/Contacts/Form'

import style from './style.module.scss'
import { Button } from 'components/Forms'
import useUser from 'UserApp/hooks/useUser'

ContactsScreen.propTypes = {
  contactId: PropTypes.string,
  emergencyContacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
  otherContacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
  hasContacts: PropTypes.bool,
  isAddingContact: PropTypes.bool,
  isEditingContact: PropTypes.bool,
  isProcessingSort: PropTypes.bool,
  onClose: PropTypes.func,
  onSort: PropTypes.func,
  petCount: PropTypes.number,
}

function ContactsScreen(props) {
  const {
    hasContacts,
    emergencyContacts,
    otherContacts,
    isEditingContact,
    isAddingContact,
    isProcessingSort,
    onClose,
    onSort,
    contactId,
    petCount,
  } = props

  const [user, setUser] = useState()
  const userData = useUser();

  useEffect(() => {
    setUser(userData);
  }, []);

  return (
    (user?.user.hasSubscription === true) ?
    (user?.user.hasSubscription &&
    <Layout
      title='Contacts'
      button={{
        to: `/contacts/new`,
        text: 'Add Contact',
      }}
    >
      <Columns>
        {hasContacts ? (
          <React.Fragment>
            <Column>
              <Text variant='strong'>Emergency</Text>
              <Text className={style.description}>
                Pet Notify messages these contacts when your pet needs help and
                you can’t respond to an alarm or lost pet alert. You can also
                assign tasks to these contacts
              </Text>
              <EmergencyContactsList
                onSort={onSort}
                contacts={emergencyContacts}
                isLoading={isProcessingSort}
              />
            </Column>
            <Column>
              <Text variant='strong'>Pet Care</Text>
              <Text className={style.description}>
                These contacts can only receive pet care task messages and they
                will not receive emergency alerts
              </Text>
              <OtherContactsList contacts={otherContacts} />
            </Column>
          </React.Fragment>
        ) : (
          <Column>
            <Text variant='strong'>No contacts added</Text>
            <Text>
              Contacts can be notified when your alarm goes off, and can be
              assigned tasks.
            </Text>
          </Column>
        )}
      </Columns>

      {(isAddingContact || isEditingContact) && (
        <Modal onClose={onClose}>
          {() => <ContactForm onClose={onClose} id={contactId} />}
        </Modal>
      )}
      </Layout>
      ) : (
        (user?.user.hasSubscription === false || user?.user.hasSubscription === null) ?
        <Layout
        title='Contacts'
        >
            <div>
                <div className={style.modal_content}>
                  <p className={style.para}>Feature only offered under Pet Notify premium</p>
                  <Button 
                    to='/settings/upgrade'
                    className={style.button}
                  >
                    Upgrade to premium
                  </Button>
                </div>
            </div>
        </Layout>
        : ""
    )
  )
}

export default ContactsScreen
