import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import api from 'services/pet-notify-api'
import Layout from 'sharedComponents/Layout/Full'
import HeaderOne from 'sharedComponents/HeaderOne'
import HeaderTwo from 'sharedComponents/HeaderTwo'
import numeral from 'numeral'
import Text from 'sharedComponents/Text'
import Button from 'sharedComponents/Button'
import Toggle from 'sharedComponents/Toggle'
import RadioButton from 'sharedComponents/RadioButton'
import nopeIcon from './images/nope.svg'
import yupIcon from './images/yup.svg'
import style from './style.module.scss'
import Loader from 'sharedComponents/Loader'
import mixPanel, { EVENTS } from 'services/mix-panel'
import PromoCode from 'UserApp/components/InlinePromoCode'
import useCaptureSignupParams from 'UserApp/hooks/useCaptureSignupParams'
import Modal from 'sharedComponents/Modal'
import { removePromoCode } from 'UserApp/store/promoCode/actions'

function useApiRequest<T>(request: () => Promise<T>, promoCode) {
  const [data, setData] = useState<T>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetch() {
      setIsLoading(true)
      const response = await request({ promoCode })
      setData(response)
      setIsLoading(false)
    }
    fetch()
  }, [promoCode])

  return {
    data,
    isLoading,
  }
}

function SignupChoosePlanScreen() {
  const dispatch = useDispatch()
  const promoCode = useSelector(
    (state) => state.promoCode && state.promoCode.code,
  )
  const { data: pricing, isLoading } = useApiRequest<Pricing>(
    api.orders.pricing,
    promoCode,
  )
  const isCapturingSignupParams = useCaptureSignupParams(location.search)
  const [isPremium, setIsPremium] = useState(true)
  const [plan, setPlan] = useState('yearly')
  const [isConfirmingChange, setIsConfirmingChange] = useState(false)

  function handleChangePlan() {
    if (promoCode && isPremium) {
      setIsConfirmingChange(true)
    } else {
      setIsPremium((v) => !v)
    }
  }

  function handleRemove() {
    dispatch(removePromoCode())
  }

  if (isLoading || !pricing || isCapturingSignupParams) {
    return <Loader />
  }

  return (
    <Layout>
      <HeaderOne>Choose your plan</HeaderOne>
      <Toggle
        enabledLabel='Premium'
        disabledLabel='Free'
        value={isPremium}
        name='isPremium'
        onChange={handleChangePlan}
        className={style.toggle}
      />
      {isPremium ? (
        <>
          <div className={style.plans}>
            <RadioButton
              id='subscriptionPlan-year'
              name='subscriptionPlan'
              value='year'
              checked={plan === 'yearly'}
              onChange={() => setPlan('yearly')}
              variant='small'
              className={style.plan}
            >
              <strong>
                {numeral(pricing.subscriptionPlans.year.amount / 12).format(
                  '$0.00',
                )}
                /mo
              </strong>{' '}
              paid annually (save{' '}
              {pricing.subscriptionPlans.year.annualDiscountPercentOff || 45}%)
            </RadioButton>
            <RadioButton
              id='subscriptionPlan-month'
              name='subscriptionPlan'
              value='month'
              checked={plan === 'monthly'}
              onChange={() => setPlan('monthly')}
              variant='small'
            >
              <strong>
                {numeral(pricing.subscriptionPlans.month.amount).format(
                  '$0.00',
                )}
                /mo
              </strong>{' '}
              paid monthly
            </RadioButton>
          </div>
          {/* 
          <ul className={style.notes}>
            <li>No commitments, cancel anytime</li>
            <li>Billed in USD</li>
          </ul> */}

          <Text className={style.notes}>
            One premium account monitors unlimited household pets Intellitags.
            No commitments, cancel anytime.
          </Text>

          <PromoCode isAnnualPlanSelected={plan === 'yearly'} size='small' />
          <div className={style.buttons}>
            <Button
              to={`/signup/premium?plan=${plan}`}
              className={style.button}
              onClick={() =>
                mixPanel.track(EVENTS.PREMIUM_PRICING_PAGE_SIGN_UP_CLICK)
              }
            >
              Sign up with Premium
            </Button>
            <Button
              variant='hollow'
              href='/activate/2'
              className={style.button}
            >
              Already purchased an Intellitag?
            </Button>
          </div>
        </>
      ) : (
        <div className='buttons'>
          <Button
            to='/signup/free'
            className={style.button}
            onClick={() =>
              mixPanel.track(EVENTS.FREE_PRICING_PAGE_SIGN_UP_CLICK)
            }
          >
            Sign up with free plan
          </Button>
          <Button variant='hollow' href='/activate/2' className={style.button}>
            Already purchased an Intellitag?
          </Button>
        </div>
      )}

      <div className={style.features}>
        <div className={style.columnHeaders}>
          <Text className={style.columnHeader}>Free</Text>
          <Text className={style.columnHeader}>Premium</Text>
        </div>

        <div className={style.feature}>
          <Text className={style.featureLabel}>
            Pet Vault document storage and sharing
          </Text>
          <div className={style.included}>
            <img src={yupIcon} alt='included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>

        <div className={style.feature}>
          <Text className={style.featureLabel}>
            Intellitag monitoring and lost pet messaging
          </Text>
          <div className={style.included}>
            <img src={nopeIcon} alt='not included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>

        <div className={style.feature}>
          <Text className={style.featureLabel}>
            Personalized engraved Intellitag for every household pet
          </Text>
          <div className={style.included}>
            <img src={nopeIcon} alt='not included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>
        <div className={style.feature}>
          <Text className={style.featureLabel}>
            Intellitag lifetime replacement if lost, worn, or damanged
          </Text>
          <div className={style.included}>
            <img src={nopeIcon} alt='not included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>

        <div className={style.feature}>
          <Text className={style.featureLabel}>
            Automatic location based & daily check-in pet-car alarms
          </Text>
          <div className={style.included}>
            <img src={nopeIcon} alt='included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>

        <div className={style.feature}>
          <Text className={style.featureLabel}>
            SOS emergency alarm notifications
          </Text>
          <div className={style.included}>
            <img src={nopeIcon} alt='included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>

        <div className={style.feature}>
          <Text className={style.featureLabel}>
            Pet care task assignment to family & care providers
          </Text>
          <div className={style.included}>
            <img src={nopeIcon} alt='included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>
        <div className={style.feature}>
          <Text className={style.featureLabel}>
            Unlimited emergency & pet care contacts
          </Text>
          <div className={style.included}>
            <img src={nopeIcon} alt='included with free' />
          </div>
          <div className={style.included}>
            <img src={yupIcon} alt='included with premium' />
          </div>
        </div>
      </div>

      {isConfirmingChange && (
        <Modal onClose={() => setIsConfirmingChange(false)}>
          <>
            <HeaderTwo>
              You&apos;re currently using a promo code that only applies to our
              premium plan
            </HeaderTwo>
            <Button
              onClick={() => {
                setIsPremium(true)
                setIsConfirmingChange(false)
              }}
              className={style.primaryButton}
            >
              Stay with Premium
            </Button>
            <Button
              variant='hollow'
              onClick={() => {
                handleRemove()
                setIsPremium(false)
                setIsConfirmingChange(false)
              }}
            >
              Switch to free
            </Button>
          </>
        </Modal>
      )}
    </Layout>
  )
}

export default SignupChoosePlanScreen
