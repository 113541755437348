import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components/Forms'
import Columns from 'components/Layout/Columns'
import Column from 'components/Layout/Column'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Layout from 'UserApp/components/Layout'
import Text from 'components/Text'
import Link from 'sharedComponents/Link'
import TaskForm from 'UserApp/containers/Tasks/Form'
import TasksList from 'UserApp/components/Tasks/List'
import TasksListedByDueDate from 'UserApp/components/Tasks/ListedByDueDate'

import style from './style.module.scss'
import useUser from 'UserApp/hooks/useUser'

TasksScreen.propTypes = {
  activeTasks: PropTypes.array,
  doneTasks: PropTypes.array,
  hasTasks: PropTypes.bool,
  isAddingTask: PropTypes.bool,
  isEditingTask: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCheck: PropTypes.func,
  onClose: PropTypes.func,
  taskId: PropTypes.string,
}

function TasksScreen(props) {
  const {
    isLoading,
    activeTasks,
    doneTasks,
    taskId,
    isAddingTask,
    isEditingTask,
    onClose,
    onCheck,
    hasTasks,
    onClearTaskHistory,
  } = props

  const [user, setUser] = useState()
  const userData = useUser();

  useEffect(() => {
    setUser(userData);
  }, []);

  return (
    (user?.user.hasSubscription === true) ?
    (user?.user.hasSubscription &&
    <Layout
      title='Tasks'
      button={hasTasks && { to: `/tasks/new`, text: `Create task` }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Columns>
          <Column>
            {hasTasks ? (
              <TasksListedByDueDate onCheck={onCheck} tasks={activeTasks} />
            ) : (
              <React.Fragment>
                <Text className={style.boldText}>
                  You haven’t created any tasks
                </Text>
                <Text className={style.text}>
                  Simplify pet care duties by assigning tasks to your contacts.
                </Text>
                <Button to='/tasks/new' small>
                  Create task
                </Button>
              </React.Fragment>
            )}
          </Column>
          <Column>
            <TasksList
              header={
                <>
                  History
                  <Link
                    className={style.headerLink}
                    onClick={onClearTaskHistory}
                  >
                    Clear task history
                  </Link>
                </>
              }
              onCheck={onCheck}
              tasks={doneTasks}
            />
          </Column>
        </Columns>
      )}

      {(isAddingTask || isEditingTask) && (
        <Modal onClose={onClose}>
          {() => <TaskForm onClose={onClose} id={taskId} />}
        </Modal>
      )}
        </Layout>
    ) : ((user?.user.hasSubscription === false || user?.user.hasSubscription === null) ?
    <Layout
    title='Tasks'
    >
        <div>
            <div className={style.modal_content}>
              <p className={style.para}>Feature only offered under Pet Notify premium</p>
              <Button 
                to='/settings/upgrade'
                className={style.button}
              >
                Upgrade to premium
              </Button>
            </div>
        </div>
      </Layout>
      : ""
    )
  )
}

export default TasksScreen
